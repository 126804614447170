import {MouseEvent, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    ADMIN_USER,
    ADMINISTRATION, CONSO_ETAT_COMPTABLE,
    CONSOLIDATION,
    EVAL_EMPLOYEES,
    EVALUATION,
    modules
} from "../../config/config.modules";
import AppBarButton from "../Tools/Action/Button/AppBarButton";
import { useUserContext } from "./UserContext";
import AccessControl, { READ, SCOPE_MENU } from "../Tools/AccessControl/AccessControl";
import HeaderBar from "../Tools/Display/HeaderBar";
import { Typography } from "@mui/material";
import pageChangeProhibited from "../Tools/PageChangeProhibited/pageChangeProhibited";

interface AppBarProps {
    isConnected: boolean,
    idModules: string,
    handleChangeConnected(newValue: boolean): any;
}

function DisplayHeaderBar({idModules}: any) {
    const dispatch = useDispatch();
    const [defaultRouteAdmin, setDefaultRouteAdmin] = useState(ADMIN_USER);
    const [defaultRouteEval, setDefaultRouteEval] = useState(EVAL_EMPLOYEES);
    const [defaultRouteConso, setDefaultRouteConso] = useState(CONSO_ETAT_COMPTABLE);

    if (idModules === ADMINISTRATION){
        dispatch({ type: "SIZE_PERIMETER", longerPerimeter: true});
        return <HeaderBar setNewRoute={setDefaultRouteAdmin} parent={ADMINISTRATION} actions={modules[1].menu} defaultRoute={defaultRouteAdmin}/>;
    }
    if (idModules === EVALUATION){
        dispatch({ type: "SIZE_PERIMETER", longerPerimeter: true});
        return <HeaderBar setNewRoute={setDefaultRouteEval} parent={EVALUATION} actions={modules[2].menu} defaultRoute={defaultRouteEval}/>;
    }
    if (idModules === CONSOLIDATION){
        dispatch({ type: "SIZE_PERIMETER", longerPerimeter: true});
        return <HeaderBar setNewRoute={setDefaultRouteConso} parent={CONSOLIDATION} actions={modules[4].menu} defaultRoute={defaultRouteConso}/>;
    }
    dispatch({ type: "SIZE_PERIMETER", longerPerimeter: false});
    return <></>;
}

export default function AppBar({isConnected, idModules, handleChangeConnected}: AppBarProps) {
    const navigate = useNavigate();
    const user = useUserContext();
    const dispatch = useDispatch();
    const isPageChangeAllowed = useSelector((state: any) => state.isPageChangeAllowed);

    return <>
        <nav className="bg-gradient-to-r from-BASE_PURPLE to-BASE_BLUE h-14 w-full">
            <div className="px-2 h-full w-full sm:px-6 lg:px-4">
                <div className="flex h-full w-full items-center justify-between">
                    <div className="flex cursor-pointer h-[70%] items-center">
                        <a
                            href={isConnected ? "../dashboard/home" : "../oauth/login"}
                            onClick={(event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>): void => {
                                event.preventDefault();
                                event.stopPropagation();
                                const nav = () => {
                                    dispatch({ type: "ALLOW_PAGE_CHANGE" });
                                    navigate(isConnected ? "../dashboard/home" : "../oauth/login");
                                };
                                if (isPageChangeAllowed)
                                    nav();
                                else
                                    pageChangeProhibited(nav);
                            }}>
                            <img
                                src={require("../../Assets/Logo.png")}
                                alt="logo"
                            />
                        </a>
                    </div>
                    <div className="hidden md:block h-full">
                        <div className="ml-10 flex font-worksans items-baseline space-x-4 h-full pt-3">
                            {isConnected && modules.map((item, index) =>
                                <AccessControl key={`${index} - ${item.path} - ${item.name}`} section={item.rules} scope={SCOPE_MENU} right={READ}>
                                    <AppBarButton item={item} idModules={idModules} key={`${item.path} - ${index}`}/>
                                </AccessControl>)}
                        </div>
                    </div>
                    {isConnected ? <div className="hidden md:block">
                        <div className="ml-4 flex items-center md:ml-6">
                            <div className="relative ml-3">
                                <div className={"flex flex-row space-x-4"}>
                                    <button type="button"
                                            onClick={() => {
                                                const nav = () => {
                                                    dispatch({ type: "ALLOW_PAGE_CHANGE" });
                                                    navigate("profile");
                                                };

                                                if (isPageChangeAllowed)
                                                    nav();
                                                else
                                                pageChangeProhibited(nav);
                                            }}
                                            className="flex flex-row space-x-3 max-w-xs items-center rounded-full text-white text-sm text-start"
                                            id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                        <div className={"flex flex-col font-worksans"}>
                                            <Typography variant="body2">{!!user ? user!.nom : ""} {!!user ? user!.prenom : ""}</Typography>
                                            <Typography
                                                variant="caption">{!!user && !!user.client ? user!.client.nom : ""} | {!!user && !!user.client ? user!.permissions.profil : ""}</Typography>
                                        </div>
                                    </button>
                                    <button onClick={async () => {
                                        const nav = () => {
                                            dispatch({ type: "ALLOW_PAGE_CHANGE" });
                                            handleChangeConnected(false);
                                            navigate("disconnect");
                                        };

                                        if (isPageChangeAllowed)
                                            nav();
                                        else
                                            pageChangeProhibited(nav);
                                    }}><img src={require("../../Assets/disconnect_icon.png")} alt={"disconnect_logo"}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> : <div className="hidden md:block">
                        <ul className="flex flex-row font-medium font-worksans text-sm text-white space-x-5">
                            <li className="hover:underline cursor-pointer" onClick={() => {
                                const nav = () => {
                                    dispatch({ type: "ALLOW_PAGE_CHANGE" });
                                    navigate("../oauth/login");
                                };

                                if (isPageChangeAllowed)
                                    nav();
                                else
                                    pageChangeProhibited(nav);
                            }}>
                                Connexion
                            </li>
                        </ul>
                    </div>}
                </div>
            </div>
        </nav>
        <DisplayHeaderBar idModules={idModules}/>
    </>;
}
